import { BlackButton } from "./Buttons";

export default function FormInput({
  placeholder,
  value,
  onChange,
  onSubmit,
  autofocus = false,
  rows = 7,
  maxLength = 999999,
  minLength = 0,
  buttonText = "Send",
  submitDisabled = false,
  buttonDesktopRounded = true,
  inputWeight = "font-normal",
}) {
  return (
    <>
      <textarea
        rows={rows}
        autoFocus={autofocus}
        className={`h-[180px] max-h-[180px] min-h-[180px] w-full rounded-xl bg-msgray-50 p-2.5 text-lg ${inputWeight}`}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxLength}
        minLength={minLength}
      />
      <BlackButton
        text={buttonText}
        onClick={onSubmit}
        className="w-full rounded-xl"
        disabled={submitDisabled}
        desktopRounded={buttonDesktopRounded}
      />
    </>
  );
}
